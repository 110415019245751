export const environment = {
    production: false,
  //  appUrl:"https://localhost:44387/",
 //appUrl: 'https://azsys.colorati.net/api/',
 appUrl: 'https://erp.colorati.net/',
    pathFiles: 'https://localhost:44387/wwwroot/Uploads/',
    site:"http://localhost:4200/",
  universityId: "dGV1OWMrM3M2MG89",
    adminType:{id:1,name:"Admin"},
    doctorType:{id:2,name:"Employee"},
  
    ReferenceId:"dGV1OWMrM3M2MG89",
  
    rowsCount:5,
    RecordPerPageOptions:[3,5,10,15,30,50,100,1000,5000,10000],

    Langs:[{id: 1,name: "English",shortName:"en",flagImage: "global",dir:"ltr",status:true}, 
    {id: 2,shortName:"ar",name: "العربية",flagImage: "global",dir:"rtl",status:true}],

    tinymceConfig: {
      height: 300,
      menubar: true,
      plugins: [
        'advlist', 'autolink', 'charmap', 'codesample', 'directionality', 'importcss',
        'link', 'lists', 'media', 'nonbreaking', 'searchreplace', 'template',
        'image', 'code', 'fullscreen', 'preview', 'table'
      ],
      toolbar:
        'fullscreen preview code formatselect | bold italic backcolor | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent | link image media table | \
        nonbreaking removeformat',
    },


    MaxUploadSize :{
      Image: 320276,
      File: 10000000
   },


    Roles :{
      Administrator: "dGV1OWMrM3M2MG89",
      CustomRole:"NnE0czY0L0NHa009"
   },

    EntitiesCodes:{
      home:"101",
      user:"102",
      role:"103",
      category:"105",
      brand:"104",
      product:"105",
      tag:"105",
      order:"105",
      reference:"105",
      position:"105",
      student:"105",
      group:"105",
      employee:"105",
      studyPlans:"105",
      studyMaterials:"105",
      semester:"105",
      studyScheduler:"105",
      materialStatement:"105",
      examSchedule:"105",
      studentRegistration:"105",
      studentMarksRegistration:"105",

      

    },

    documentType : {
      IDPhoto:"dkYzaC9jcWk4M1U9",
      BirthCertificate:"RmpEWFZadEwxaGc9",
      HighSchoolCertificate:"SG5nSE1Gd1NKdjA9",
      BachelorDegree:"QjNiTjJabndWalU9",
      MasterDegree:"dVoyMHZ1cjZMRjA9",
      PhD:"NEdoZ25tblBONFk9",
      Transcript:"LzFsZkxkaW9xK3c9"
    },



    maritalStatusType : {
      single:"dkYzaC9jcWk4M1U9",
    }
  };