import { Component, OnInit } from '@angular/core';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../_services/authservice';
import { Router } from '@angular/router';
import { BaseService } from '../../../_services/Base.Service';
import { AppServiceService } from '../../../_services/app-service.service';
import { NzNotificationModule, NzNotificationService } from 'ng-zorro-antd/notification';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { RouterModule } from '@angular/router';
import { NgPipesModule} from 'ngx-pipes';
import { SignalRService } from '../../../_services/signal-r.service';
import { CommonModule } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../../../_services/layout.service';
import { appRoutes } from '../../../app.routes';

@Component({
  selector: 'app-top-bar',
  standalone: true,
  imports: [CommonModule ,NgPipesModule,RouterModule,NzIconModule,NzLayoutModule,NzBadgeModule,NzAvatarModule,NzDropDownModule,NzCardModule,NzSkeletonModule,NzNotificationModule,],
  templateUrl: './top-bar.component.html',
  styleUrl: './top-bar.component.scss'
})
export class TopBarComponent implements OnInit {

  _Notifications:any[]=[];
  _ChatNotifications:any[]=[];

  userInfo=JSON.parse(localStorage.getItem("userInfo")!);
  constructor(
    public translateService :TranslateService,public layoutService:LayoutService,
      public AuthService: AuthService,private notification: NzNotificationService,
     public signalRService: SignalRService,
      private router: Router,public baseService:BaseService,public appService:AppServiceService
  ) {

  }

  env:string=environment.pathFiles;
  loading=false;
  
  logout() {
    localStorage.removeItem('token');

    this.router.navigate(['/login'])
   // this.AuthService.logout();
  }


getNotifications()
{
 
//   this.baseService.get('Notification/GetNotificationsByUserId/'+this.AuthService.currentUser.id).subscribe(result=> {
//     this._Notifications=result;
//     this._Notifications.reverse();
//    // console.log(this._Notifications)
//           if(this._Notifications.length>0)
//          this.createBasicNotification(this._Notifications[0])
// });
}

getChatNotifications()
{
 
//   this.baseService.get('ChatNotification/GetChatNotificationsByUserId/'+this.AuthService.currentUser.id).subscribe(result=> {
//     if(result){
//       this._ChatNotifications=result;
//       this._ChatNotifications.reverse();

//     }

// });
}


deleteNoti(notiId:number)
{
  this.baseService.delete('Notification/DeleteNotification/'+notiId).subscribe(result=> {
   this.getNotifications();
  });
 // this.router.navigate(['/'+noti.link+'/'+noti.param]);
}

deleteChatNoti(chatNotiId:number)
{
  this.baseService.delete('ChatNotification/DeleteChatNotification/'+chatNotiId).subscribe(result=> {
   this.getChatNotifications();
  });
 // this.router.navigate(['/'+noti.link+'/'+noti.param]);
}

deleteAllNoti(){

  // this.baseService.delete('Notification/DeleteAllNotification/'+this.AuthService.currentUser.id).subscribe(result=> {
  //  this.getNotifications();
  // });
}

deleteAllChatNoti(){

  // this.baseService.delete('ChatNotification/DeleteAllChatNotification/'+this.AuthService.currentUser.id).subscribe(result=> {
  //  this.getChatNotifications();
  // });
}

merge(val1:string,val2:string)
{
  return val1+" \n\r"+val2+"";
}

ngOnInit()
{

  // this.langService.SelectedLang.subscribe(lang=>{
  //   this.SelectedLang=lang;
  //})

//   this.signalRService.startConnectionNotification();
  
//   this.signalRService.hubNotiConnection!.on('newConnection', (res:any) => {
//     console.log(res);
  
//     this.signalRService.hubNotiConnection!.invoke("Register",this.AuthService.currentUser.id).then((res2:any)=>{
//       console.log(res2);
//     });
//   });

//   this.signalRService.hubNotiConnection!.on('notification_data', () => {
//    this.getNotifications()

// });

// this.signalRService.hubNotiConnection!.on('ChatNotification_data', () => {
//   this.getChatNotifications()


// });

// this.signalRService.hubNotiConnection!.onreconnected( () => {
//     this.signalRService.hubNotiConnection!.invoke("Register",this.AuthService.currentUser.id).then((res2:any)=>{
//       console.log(res2);


// });
// });

// this.getNotifications();
// this.getChatNotifications();

}


selectedChat:any;
startCaseChat(_selectedChat:any)
{

  this.selectedChat=_selectedChat;
  this.visible = true;
}

visible = false;

open(): void {
  this.visible = true;
}

close(): void {
  this.visible = false;
}
drwerDir():String
{
  // if(this.langService._SelectedLang.dir=="rtl")
  // {
  //   return "left";
  // }
   return "right"
}

goToSettings()
{
  this.router.navigate([appRoutes.settings]);
}

editAccount()
{
  this.router.navigate(['System/EditAccount']);
}

  createBasicNotification(res:any): void {

 //console.log(res);
    if(this.translateService.currentLang=='ar')
    {
    this.notification.blank('','',{
            nzPlacement: 'topLeft'
    });
    
    this.notification.blank(
      res.createdByName,
      res.trTitle
    );
    }
    else
    {
    this.notification.blank('','',{

       nzPlacement: 'topRight'
    });
    
    this.notification.blank(
      res.createdByName,
      res.title
    );
    }
  }

  changeLang(lang:string){

    localStorage.setItem('lang',lang=="ar"?"ar":"en")
    this.translateService.use(lang=="ar" ? lang : 'en');
    const dom: any = document.querySelector('body');
    this.layoutService.changeLang(lang);

    
    if (lang=="ar") {
      dom.classList.add('app_rtl');

    } else {
      dom.classList.remove('app_rtl')


  }
  }
}
