import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../_services/authservice';
import { AppServiceService } from '../../../_services/app-service.service';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { appRoutes } from '../../../app.routes';


@Component({
  selector: 'app-side-bar',
  standalone: true,
  imports: [NzIconModule, NzMenuModule, RouterModule, TranslateModule, CommonModule],
  templateUrl: './side-bar.component.html',
  styleUrl: './side-bar.component.scss'
})
export class SideBarComponent implements OnInit {



  constructor(public translateService: TranslateService,public authService: AuthService, public appService: AppServiceService,
   ) {
      this.authService.refreshToken().toPromise().then(()=>{
    this.getPermissionMenu();
     });


  }


  getPermissionMenu() {
    //var cUser=JSON.parse(this.authService.CurrentUser());
    this.ActiveMenu = [];
    for (let i = 0; i < this.sideMenu.length; i++) {
      if (this.sideMenu[i].link == "" || this.authService.hasPermission(this.sideMenu[i].link)) {

        let temp: SideMenu = { level: 1, title: this.sideMenu[i].title, icon: this.sideMenu[i].icon, open: this.sideMenu[i].open, selected: this.sideMenu[i].selected, disabled: this.sideMenu[i].disabled, link: this.sideMenu[i].link };


        if (this.sideMenu[i].children) {
          temp.children = [];
          for (let k = 0; k < this.sideMenu[i].children!.length; k++) {
            if (this.authService.hasPermission(this.sideMenu[i].children![k].link)) {

              let chTemp: SideMenu = { level: 2, title: this.sideMenu[i].children![k].title, icon: this.sideMenu[i].children![k].icon, open: this.sideMenu[i].children![k].open, selected: this.sideMenu[i].children![k].selected, disabled: this.sideMenu[i].children![k].disabled, link: this.sideMenu[i].children![k].link };

              temp.children.push(chTemp);
            }
          }
        }
        if (temp.link != "") {
          this.ActiveMenu.push(temp);

        }
        else {
          if (temp.children!.length > 0) {
            this.ActiveMenu.push(temp);
          }
        }
      }
    }
  }

  ngOnInit() {
    //this.ActiveMenu=this.sideMenu;
  }

  ActiveMenu: SideMenu[] = [];


  sideMenu: SideMenu[] = [
    {
      level: 1,
      title: 'dashboard',
      icon: 'dashboard',
      open: false,
      selected: false,
      disabled: false,
      link: "welcome",
      
    },

    {
      level: 1,
      title: 'structure',
      icon: 'apartment',
      open: true,
      selected: false,
      disabled: false,
      link: "",
      children: [

        {
          level: 2,
          title: 'collegesAndInstitutes',
          icon: 'bars',
          open: false,
          selected: false,
          disabled: false,
          link: `/${appRoutes.listReference}`
        },
   
        {
          level: 2,
          title: 'studyPlans',
          icon: 'bars',
          open: false,
          selected: false,
          disabled: false,
          link: `/${appRoutes.listStudyPlans}`,
        },

        {
          level: 2,
          title: 'studyMaterials',
          icon: 'bars',
          open: false,
          selected: false,
          disabled: false,
          link: `/${appRoutes.listStudyMaterials}`,
        },

        {
          level: 2,
          title: 'semesters',
          icon: 'bars',
          open: false,
          selected: false,
          disabled: false,
          link: `/${appRoutes.listSemester}`,
        },
        
      



      ]
    },

    {
      level: 1,
      title: 'administrativeAffairs',
      icon: 'audit',
      open: true,
      selected: false,
      disabled: false,
      link: "",
      children: [

        {
          level: 2,
          title: 'employeeManagement',
          icon: 'bars',
          open: false,
          selected: false,
          disabled: false,
          link: `/${appRoutes.listEmployee}`
        },

        // {
        //   level: 2,
        //   title: 'positions',
        //   icon: 'bars',
        //   open: false,
        //   selected: false,
        //   disabled: false,
        //   link: `/${appRoutes.listPosition}`,
        // },

      ]
    },

    {
      level: 1,
      title: 'studentAffairs',
      icon: 'team',
      open: true,
      selected: false,
      disabled: false,
      link: "",
      children: [

        {
          level: 2,
          title: 'studentsManagement',
          icon: 'bars',
          open: false,
          selected: false,
          disabled: false,
          link: `/${appRoutes.listStudent}`
        },

        {
          level: 2,
          title: 'groupManagement',
          icon: 'team',
          open: false,
          selected: false,
          disabled: false,
          link: `/${appRoutes.listGroup}`
        },
        {
          level: 2,
          title: 'studyScheduler',
          icon: 'schedule',
          open: false,
          selected: false,
          disabled: false,
          link: `/${appRoutes.studyScheduler}`
        },

        {
          level: 2,
          title: 'studentsRegistration',
          icon: 'team',
          open: false,
          selected: false,
          disabled: false,
          link: `/${appRoutes.studentRegistration}`
        },

 
      ]
    },




 
    
    {
      level: 1,
      title: 'examinationAffairs',
      icon: 'bars',
      open: true,
      selected: false,
      disabled: false,
      link: "",
      children: [
        // {
        //   level: 2,
        //   title: 'examSchedule',
        //   icon: 'schedule',
        //   open: false,
        //   selected: false,
        //   disabled: false,
        //   link: `/${appRoutes.examSchedule}`
        // },

        {
          level: 2,
          title: 'studentsMarksRegistration',
          icon: 'team',
          open: false,
          selected: false,
          disabled: false,
          link: `/${appRoutes.studentMarksRegistration}`
        },

      

      ]
    },

    {
      level: 1,
      title: 'reports',
      icon: 'dashboard',
      open: true,
      selected: false,
      disabled: false,
      link: "",
      children: [

        {
          level: 2,
          title: 'test1',
          icon: 'bars',
          open: false,
          selected: false,
          disabled: false,
          link: `/${appRoutes.listBrand}`
        },
        {
          level: 2,
          title: 'test2',
          icon: 'bars',
          open: false,
          selected: false,
          disabled: false,
          link: `/${appRoutes.listBrand}`,
        },


        



      ]
    },




    



    // {
    //   level: 1,
    //   title: 'store',
    //   icon: 'bars',
    //   open: true,
    //   selected: false,
    //   disabled: false,
    //   link: "",
    //   children: [
    //     {
    //       level: 2,
    //       title: 'categoriesManagement',
    //       icon: 'bars',
    //       open: false,
    //       selected: false,
    //       disabled: false,
    //       link: `/${appRoutes.listCategory}`,
    //     },
    //     {
    //       level: 2,
    //       title: 'brandsManagement',
    //       icon: 'bars',
    //       open: false,
    //       selected: false,
    //       disabled: false,
    //       link: `/${appRoutes.listBrand}`,
    //     },
    //     {
    //       level: 2,
    //       title: 'productsManagement',
    //       icon: 'bars',
    //       open: false,
    //       selected: false,
    //       disabled: false,
    //       link: `/${appRoutes.listProduct}`,
    //     },
    //     {
    //       level: 2,
    //       title: 'productsTagsManagement',
    //       icon: 'bars',
    //       open: false,
    //       selected: false,
    //       disabled: false,
    //       link: `/${appRoutes.listTag}`,
    //     },
    //     {
    //       level: 2,
    //       title: 'ordersManagement',
    //       icon: 'bars',
    //       open: false,
    //       selected: false,
    //       disabled: false,
    //       link: `/${appRoutes.listOrder}`,
    //     },
    //   ]
    // },


    {
      level: 1,
      title: 'products',
      icon: 'bars',
      open: true,
      selected: false,
      disabled: false,
      link: "",
      children: [


      ]
    },



    {
      level: 1,
      title: 'settings',
      icon: 'form',
      open: true,
      selected: false,
      disabled: false,
      link: "",
      children: [
        {
          level: 2,
          title: 'statusManagement',
          icon: 'bars',
          open: false,
          selected: false,
          disabled: false,
          link: "System/StatusManagement",
        },
        {
          level: 2,
          title: 'locationManagement',
          icon: 'bars',
          open: false,
          selected: false,
          disabled: false,
          link: "System/LocationManagement",
        },
        {
          level: 2,
          title: 'languageManagement',
          icon: 'bars',
          open: false,
          selected: false,
          disabled: false,
          link: "System/LanguageManagement",
        },

      ]
    },

    {
      level: 1,
      title: 'system',
      icon: 'setting',
      open: true,
      selected: false,
      disabled: false,
      link: "",
      children: [

        {
          level: 2,
          title: 'usersManagement',
          icon: 'usergroup-add',
          open: false,
          selected: false,
          disabled: false,
          link: `/${appRoutes.listUser}`
        },
        {
          level: 2,
          title: 'rolesManagement',
          icon: 'bars',
          open: false,
          selected: false,
          disabled: false,
          link: `/${appRoutes.listRole}`,
        },



      ]
    }
  ];


}

export class SideMenu {

  // constructor() {
  //   this.level=0
  //   this.title=""
  //   this.icon=""
  //   this.open=false
  //   this.selected=false
  //   this.disabled=false
  //   this.link=""
  //   this.children=[];
  // }
  level: number | undefined
  title: string | undefined
  icon: string | undefined
  open: boolean | undefined
  selected: boolean | undefined
  disabled: boolean | undefined
  link: string | undefined
  children?: SideMenu[]
}
